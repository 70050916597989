import "./style.css";
import { connect } from "react-redux";
import { useState, useEffect } from "react"; 
import Nav from "../../components/Nav";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { useNavigate, Link  } from 'react-router-dom';
import Select from 'react-select';
import { getShipmentList } from "../../actions/shipmentAction";
import Loading from "../../components/modals/loading";

const shipMentFakeData = [{
  user_login: "114",
  nickname: "114",
  confirm: 20,
  shiped: 15,
  unship: 5
}]

function ShipmentPage(props) {
  const ShipmentSortList = [
    { name: "user_login", sortKey: "down", title: "客戶代號" },
    { name: "nickname", sortKey: "down", title: "姓名" },
    { name: "required_total", sortKey: "down", title: "已確認" },
    { name: "shipped_total", sortKey: "down", title: "已出貨" },
    { name: "unshipped_total", sortKey: "down", title: "未出貨" },
  ];

  const [ shipmentList, setShipmentList ] = useState([]);
  const [ shipmentSortSelect, setShipmentSortSelect ] = useState("user_login");
  const [ sortShipmentList, setSortShipmentList ] = useState(ShipmentSortList);
  const [ yearSelected, setYearSelected ] = useState(null);
  const [ yearOptions, setYearOptions ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const currentYear = new Date().getFullYear();
  const navigate  = useNavigate(); 

  useEffect(() => {
    const options = [];
    
    for (let year = 2023; year <= currentYear + 1; year++) {
      options.push({ value: year, label: (year - 1911).toString() });
    }

    setYearOptions(options);

    // 设置预选值为今年
    const currentYearOption = options.find((option) => option.value === currentYear);
    setYearSelected(currentYearOption);
  }, []);

  useEffect(()  => {
    if(yearSelected) {
      setLoading(true);
      getShipment();
    }
  }, [yearSelected]);

  async function getShipment() {
    const result = await getShipmentList(yearSelected.label, localUser.id, localUser.accessToken);
    
    if(result != null ) {
      setShipmentList(result);
      setLoading(false);
    }
  }

  const handleShipmentSort = (sortKey) => {
    setShipmentSortSelect(sortKey);
    handleShipmentSortChange(sortKey)
  };

  const handleYearChange = (selected) => {
    setYearSelected(selected);
  };

  const handleShipmentSortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...shipmentList];
    let tempSortItemList = [...sortShipmentList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortShipmentList(tempSortItemList);
    setShipmentList(tempSortedList);
  };

  const sortByKey = (array, sortKey, sortRule) => {
    if (sortRule === "down") {
      return array.sort((a, b) => {
        return ((a[sortKey] < b[sortKey]) ? -1 : ((a[sortKey] > b[sortKey]) ? 1 : 0));
      });
    }
    else {
      return array.sort((a, b) => {
        return ((a[sortKey] > b[sortKey]) ? -1 : ((a[sortKey] < b[sortKey]) ? 1 : 0));
      });
    }
  };

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? <BiSolidDownArrow className="sort-icon" /> : <BiSolidUpArrow className="sort-icon" />
  };

  const handleUnShipedClick = (shipment) => {
    const url = `/unship?user_login=${shipment.user_login}&year=${yearSelected.label}`;
    if(shipment.unshipped_total != 0) {
      window.open(url,'_blank');
    }
  }

  const handleShippedClick = (shipment) => {
    const url = `/shipped?user_login=${shipment.user_login}&year=${yearSelected.label}`;
    if(shipment.shipped_total != 0) {
      const data = {
        delivery_fee: shipment.delivery_fee,
        program_fee: shipment.program_fee,
      }
      sessionStorage.setItem("feeData", JSON.stringify(data));
      window.open(url,'_blank');
    }
  }

  const handleShipConfirmClick = (shipment) => {
    const url = `/shipconfirm?user_id=${shipment.user_id}&user_login=${shipment.user_login}&year=${yearSelected.label}`;
    if(shipment.required_total != 0) {
      window.open(url,'_blank');
    }
  }

  return(
    <div className="shipment-root">
      <Nav/>
      <div className="manager-background">
        <div className="manager-title">出貨</div>
        <div className="main-display">
          <div className="shipment-button-bar">
            <Select className="shipment-year-select" menuPlacement="top" value={yearSelected} options={yearOptions} onChange={handleYearChange}/>
          </div>
          <div className="shipment-data-form">
            <table>
              <thead>
                <tr>
                  {ShipmentSortList.map((content, index) => {
                    return(
                      <th key={index} onClick={() => { handleShipmentSort(content.name) }}>
                        {content.title}
                        {shipmentSortSelect === content.name ?
                          renderSortIcon(content.sortKey)
                          : null}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {shipmentList.length > 0 ? shipmentList.map((shipment, index) => {
                  if(shipment.user_login !== "000") {
                    return(
                      <tr key={index} className={`hover ${index % 2 != 0 ? "odd-row" : ""}`}>
                        <td>{shipment.user_login}</td>
                        <td>{shipment.nickname}</td>
                        <td className={`${shipment.required_total != 0 ? "pointer button-text" : ""}`} onClick={() => handleShipConfirmClick(shipment)}>{shipment.required_total}</td>
                        <td className={`${shipment.shipped_total != 0 ? "pointer button-text" : ""}`} onClick={() => handleShippedClick(shipment)}>{shipment.shipped_total}</td>
                        <td className={`${shipment.unshipped_total != 0 ? "pointer button-text" : ""}`} onClick={() => handleUnShipedClick(shipment)}>{shipment.unshipped_total}</td>
                      </tr>
                    )
                  }
                }) : ""}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Loading loading={loading}/>
    </div>
  )
}

export default connect(
  null,
  { }
)(ShipmentPage);