import { useNavigate } from "react-router-dom"; 
import { useState, useEffect } from "react"; 
import { connect } from "react-redux";
import { FormControl } from "react-bootstrap";
import { addUser } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux/";
import Nav from "../../components/Nav";
import PrivateConfirm from "../../components/modals/privateConfirm";
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa";
import "./style.css";

function RegisterPage(props) { 
  const [account, setAccount] = useState(""); 
  const [password, setPassword] = useState(""); 
  const [name, setName] = useState(""); 
  const [privateConfirmShow, setPrivateConfirmShow] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState("");
  const navigate  = useNavigate(); 

  async function registerUser() {
    if(account == "" || password == "" || name == "") {
      window.alert("電子信箱或密碼或姓名其中一個欄位為空值，請輸入完整內容");
    } else if(password.length > 12) {
      window.alert("密碼過長，請輸入12個字元以內");
    } else {
      const user = {
        user_login: "000",
        user_pass: password,
        nickname: name,
        telephone: "0",
        account_level:2,
        user_email: account,
        user_group: 0,
      }
      const result = await addUser(user, "register");
      if(result) {
        navigate("/login");
      } else {
        window.alert("註冊失敗")
      }
    }
    
  };

  const handleShowPrivateConfirm = (title) => {
    setPopUpTitle(title);
    setPrivateConfirmShow(true);
  };

  const handleOnHidePrivateConfirm = () => {
    setPrivateConfirmShow(false);
  };

  const handlConfirmPrivateRole = () => {
    setConfirm(!confirm);
  };
  
  return ( 
    <>
    <div className="register-background">
      <Nav/>
        <h3 className="service-title margin-button-none">
          5205 我愛零股
        </h3>
        <h3 className="service-title margin-top-none">
          會員註冊
        </h3>
        <div className="register-box">
          <div className="register-input-box">
            <span className="box-hint">
              電子信箱
            </span>
            <FormControl
              id="account"
              bsPrefix="user-register-input"
              type="email"
              aria-label="E-mail"
              aria-describedby="basic-addon1"
              onChange={(e) => { 
                setAccount(e.target.value); 
              }}/>
          </div>
          <div className="register-input-box">
            <span className="box-hint">
              密碼
            </span>
            <FormControl
              bsPrefix="user-register-input"
              id="password"
              type="password"
              aria-label="密碼"
              aria-describedby="basic-addon1"
              onChange={(e) => { 
                setPassword(e.target.value); 
              }}/>
          </div>
          <div className="register-input-box">
            <span className="box-hint">
              姓名
            </span>
            <FormControl
              id="name"
              bsPrefix="user-register-input"
              type="text"
              aria-label="name"
              aria-describedby="basic-addon1"
              onChange={(e) => { 
                setName(e.target.value); 
              }}/>
          </div>
          <div>
          {confirm ?
            <FaRegCheckSquare onClick={handlConfirmPrivateRole}/> : 
            <FaRegSquare onClick={handlConfirmPrivateRole}/>}
            <span className="font-size-midun">
              請詳閱以下的 
              <span className="private-confirm-text" onClick={() => handleShowPrivateConfirm("隱私權政策")}>隱私權政策</span>
              與 
              <span className="private-confirm-text" onClick={() => handleShowPrivateConfirm("一般使用服務條款")}>一般使用服務條款</span><br/>
              在您同意各項服務條款並勾選，才能繼續加入或完成會員的註冊程序
            </span>
          </div>
          <div
            className="register-btn-block">
            <button
              id="registerBtn"
              className="user-register-btn"
              onClick={registerUser}
              disabled={!confirm}>
              註冊
            </button>
          </div>
          <div className="margin-top font-size-midun">
          1 電投，免交單<br/>
          2 電投圖，免上傳<br/>
          3 交單，便捷
          </div>
        </div>
    </div>
    <PrivateConfirm show={privateConfirmShow} onHide={handleOnHidePrivateConfirm} title={popUpTitle}/>
    </>
    
  ); 
}

export default connect(
  null, { }
)(RegisterPage);