import { useNavigate } from "react-router-dom"; 
import { useState, useEffect } from "react"; 
import { 
  getUserByLevel, 
  getphyIdList, 
  addId,
  editId,
  removeId, 
  getIdListByBelogId, 
  addUser, 
  editUser, 
  uploadPhyPic, 
  getPhyIdPic } from "../../actions/adminAction";
import { useDispatch, useSelector } from "react-redux/";
import { AiOutlinePlusCircle, AiOutlineDelete, AiOutlineUpload, AiFillEdit, AiOutlineCheckCircle, AiOutlineCloseCircle} from "react-icons/ai";
import { connect } from "react-redux";
import "./style.css";
import { setAccount, setIdList } from "../../actions";
import Nav from "../../components/Nav";
import NewId from "../../components/modals/newId";
import Confirm from "../../components/modals/confirm";
import UploadPhyPic from "../../components/modals/uploadPhyPic";
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa";
import { BiSolidUpArrow, BiSolidDownArrow, BiPlus, BiMinus } from "react-icons/bi";
import SignUp from "../../components/modals/signUp";
import { MDBBtn, MDBInputGroup } from "mdbreact";

function IdManagementPage(props) {
  const accountSortItemList = [
    { name: "user_login", sortKey: "down", title: "帳號" },
    { name: "user_email", sortKey: "down", title: "電子郵件" },
    { name: "user_pass", sortKey: "down", title: "網站密碼" },
    { name: "program_pass", sortKey: "down", title: "5205密碼" },
    { name: "nickname", sortKey: "down", title: "姓名" },
    { name: "telephone", sortKey: "down", title: "電話" },
    { name: "program_fee", sortKey: "down", title: "程式費" },
    { name: "delivery_fee", sortKey: "down", title: "運費" },
    { name: "user_group", sortKey: "down", title: "群組" },
  ];

  const idSortItemList = [
    { name: "phy_id_string", sortKey: "down", title: "身分證字號" },
    { name: "name", sortKey: "down", title: "姓名" },
    { name: "cert_type", sortKey: "down", title: "證件" },
    { name: "seq", sortKey: "down", title: "順序" },
  ];

  const [ showNewId, setShowNewId ] = useState(false);
  const [ newIdUserId, setNewUserId ] = useState(0);
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ showConfirmAll, setShowConfirmAll ] = useState(false);
  const [ showEditUser, setShowEditUser ] = useState(false);
  const [ showUploadPhyPic, setShowUploadPhyPic ] = useState(false);
  const [ removeIdInfo, setRemoveIdInfo ] = useState([]);
  const [ accountList, setAccountList ] = useState([]);  
  const [ phyIdList, setPhyIdList ] = useState([]);  
  const [ expandedRows, setExpandedRows ] = useState([]);
  const [ selectedPhyIds, setSelectedPhyIds ] = useState([]);
  const [ selectedPhyId, setSelectedPhyId ] = useState(null);
  const [ selectedUser, setSelectedUser ] = useState([]);
  const [ selectAllPhyIds, setSelectAllPhyIds ] = useState(false);
  const [ accountSortSelect, setAccountSortSelect ] = useState("user_login");
  const [ sortAccountList, setSortAccountList ] = useState(accountSortItemList);
  const [ phyIdSortSelect, setPhyIdSortSelect ] = useState("phy_id_string");
  const [ sortPhyIdList, setSortPhyIdList ] = useState(idSortItemList);
  const [ showSignUp, setShowSignUp ] = useState(false);
  const [ uploadPicPhyId, setUploadPicPhyId ] = useState(false);
  const [ showEditPhyIdPic, setShowEditPhyIdPic ] = useState(null);
  const [ showEditPhyId, setShowEditPhyId ] = useState(null);
  const [ editPhyId, setEditPhyId ] = useState(null);
  const [ editPhyIdList, setEditPhyIdList ] = useState(null);
  const [ searchText, setSearchText ] = useState("");
  const [ search, setSearch ] = useState("");
  const [ searchPhyIdList, setSearchPhyIdList ] = useState([]);
  const [ searchAccountList, setSearchAccountList ] = useState([]);

  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const dispatch = useDispatch();
  const navigate  = useNavigate(); 

  async function getUser() {
    const result = await getUserByLevel(2, localUser.accessToken);
    if(result != null ) {
      setAccountList(result);
    }
  }

  async function getIdList() {
    const idList = await getphyIdList(localUser.accessToken);
    if(idList != null ) {
      setPhyIdList(idList);
    }
  };

  async function getIdListByUserId() {
    const phyIdList = await getIdListByBelogId(localUser.id, localUser.accessToken);
    if(phyIdList != null ) {
      setPhyIdList(phyIdList);
    }
  };

  useEffect(() => {
    const localUser = JSON.parse(sessionStorage.getItem("user"));
    if (!localUser) {
      navigate("/")
    }
  }, []);

  useEffect(() => {
    if(localUser) {
      if(localUser.account_level === 1) {
        getUser();
        getIdList();
      } else {
        getIdListByUserId();
      }
    }
  }, []);

  useEffect(() => {
    if(search != "") {
      let tempPhyIdList = [];
      let tempAccountList = [];
      if(accountList != null) {
        const searchAccountList = accountList.filter(account => account.user_login.includes(search));
        if(searchAccountList.length != 0) {
          searchAccountList.forEach(account => {
            tempAccountList.push(account);
          });
        }
        setSearchAccountList(tempAccountList);
      }
    } else {
      setExpandedRows([]);
      setSearchAccountList([]);
    }
  }, [search]);
  
  const reLoadWindows = () => {
    window.location.reload();
  }
  
  const handleOnShow = async (record) => {
    setShowNewId(true);
    setNewUserId( record.id );
  };

  const handleOnHide = () => {
    setShowNewId(false);
  };

  const handleOnShowConfirm = async (record) => {
    setShowConfirm(true);
    setSelectedPhyId(record);
    const idExists = removeIdInfo.some(item => item.id === record.id);
    if(!idExists) {
      setRemoveIdInfo([...removeIdInfo, {id: record.id}]);
    }
  };

  const handleOnHideConfirm = () => {
    setShowConfirm(false);
  };

  const hadelNewPhyId = async (state) => {
    const result = await addId(state.phy_id_string, state.name, newIdUserId, localUser.accessToken);
    if(result) {
      reLoadWindows()
      handleOnHide();
    }
  }

  const hadelEditPhyId = async (state) => {
    const result = await editId(editPhyId.id, editPhyId.belong_u_id, state.phy_id_string, state.name, state.cert_type, state.seq, localUser.accessToken);
    if(result) {
      reLoadWindows()
      handleOnHide();
    }
  }

  const handelRemoveId = async () => {
    const result = await removeId(removeIdInfo, localUser.accessToken);
    if(result) {
      reLoadWindows()
      handleOnHideConfirm();
    }
  }

  const handleToggleSublist = (accountID) => {
    if (expandedRows.includes(accountID)) {
      setExpandedRows(expandedRows.filter((id) => id != accountID));
    } else {
      setExpandedRows([...expandedRows, accountID]);
    }
  };

  const togglePhyIdSelection = (id) => {
    if (selectedPhyIds.includes(id)) {
      setSelectedPhyIds((prevIds) => prevIds.filter((selectedId) => selectedId != id));
    } else {
      setSelectedPhyIds((prevIds) => [...prevIds, id]);
    }
    if(selectAllPhyIds) {
      setSelectAllPhyIds(false);
    }
  };

  const toggleAllPhyIdSelection = (accountId) => {
    for(let i=0; i<phyIdList.length; i++) {
      if(!selectAllPhyIds) {
        if (!selectedPhyIds.includes(phyIdList[i].id) && phyIdList[i].belong_u_id == accountId ) {
          setSelectedPhyIds((prevIds) => [...prevIds, phyIdList[i].id]);
        }
      } else {
        setSelectedPhyIds((prevIds) => prevIds.filter((selectedId) => selectedId != phyIdList[i].id));
      }
    }
    if(selectAllPhyIds) {
      setSelectAllPhyIds(false);
    } else {
      setSelectAllPhyIds(true);
    }
  };

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? <BiSolidDownArrow className="sort-icon" /> : <BiSolidUpArrow className="sort-icon" />
  };

  const sortByKey = (array, sortKey, sortRule) => {
    if (sortRule === "down") {
      return array.sort((a, b) => {
        return ((a[sortKey] < b[sortKey]) ? -1 : ((a[sortKey] > b[sortKey]) ? 1 : 0));
      });
    }
    else {
      return array.sort((a, b) => {
        return ((a[sortKey] > b[sortKey]) ? -1 : ((a[sortKey] < b[sortKey]) ? 1 : 0));
      });
    }
  }

  const handleUserSort = (sortKey) => {
    setAccountSortSelect(sortKey);
    handleAccountSortChange(sortKey)
  };

  const handleAccountSortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...accountList];
    let tempSortItemList = [...sortAccountList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortAccountList(tempSortItemList);
    setAccountList(tempSortedList);
  };

  const handlePhyIdSort = (sortKey) => {
    setPhyIdSortSelect(sortKey);
    handlePhyIdSortChange(sortKey)
  };

  const handlePhyIdSortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...phyIdList];
    let tempSortItemList = [...sortPhyIdList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortPhyIdList(tempSortItemList);
    setPhyIdList(tempSortedList);
  };

  const handleOnShowConfirmAll = () => {
    setShowConfirmAll(true);
  };

  const handleOnHideConfirmAll = () => {
    setShowConfirmAll(false);
  };

  const handelRemoveAllPhyId = async () => {
    const reBuildList = [];
    for(let i = 0; i< selectedPhyIds.length; i++) {
      reBuildList.push({id: selectedPhyIds[i]});
    }
    const result = await removeId(reBuildList, localUser.accessToken);
    if(result) {
      reLoadWindows()
      handleOnHideConfirmAll();
    }
  }

  const handelShowEditUser = (id) => {
    const selectedUser = accountList.filter(user => user.id === id);
    setSelectedUser(selectedUser);
    setShowEditUser(true);
  }

  const handelOnHideEditUser = () => {
    setShowEditUser(false);
  }

  const handleOnShowSignUp = async () => {
    setShowSignUp(true);
  }

  const handleOnHideSignUp = () => {
    setShowSignUp(false);
  }

  const handelShowEditPhyIdPic = (phyId) => {
    setUploadPicPhyId(phyId);
    setShowEditPhyIdPic(true);
  }

  const handelOnHideEditPhyIdPic = () => {
    setShowEditPhyIdPic(false);
  }

  const handelShowEditPhyId = (phyId, idList) => {
    setShowEditPhyId(true);
    setEditPhyId(phyId);
    setEditPhyIdList(idList);
  }

  const handelOnHideEditPhyId = () => {
    setShowEditPhyId(false);
  }

  async function handelUploadFile(file) {
    const result = await uploadPhyPic(uploadPicPhyId, file, localUser.accessToken);
    if(result) {
      handelOnHideUploadPhyPic();
      handelOnHideEditPhyIdPic();
      window.location.reload(false);
    } else {
      window.alert("file upload fail");
    }
  }

  const handelPicUpload = (phyId) => {
    setUploadPicPhyId(phyId);
    setShowUploadPhyPic(true);
  }

  const handelOnHideUploadPhyPic = () => {
    setShowUploadPhyPic(false)
  }

  async function signUp(user) {
    const allValuesPresent = Object.values(user).every(value => {
      return typeof value  === 'number' || (typeof value === 'string' && value !== "");
    });
    if (allValuesPresent) {
      const result = await addUser(user, "create");
      if(result) {
        window.location.reload();
      } 
    }else {
      window.alert("有欄位沒有值");
    }
  }

  const handleDownloadClick = async (phyId) => {
    getPhyIdPic(phyId);
  }

  const handleTranslateCertType = (cert_type) => {
    switch (cert_type) {
      case 0:
        return("沒正本");
      case 1:
        return("戶口名簿");
      case 2:
        return("戶籍");
      case 3:
        return("身分證");
      case 4:
        return("健保卡");
      case 5:
        return("駕照");
      default:
        return("沒正本");
    }
  }

  function handleSearchTextChange(e) {
    setSearchText(e.target.value);
    setSearch(e.target.value);
  }

  function handleSearch() {
    setSearch(searchText);
  }

  function handleKeyPress(e) {
    if(e.which === 13) 
    handleSearch();
  }

  async function handelEditUser(user) {
    const allValuesPresent = Object.values(user).every(value => {
      return typeof value  === 'number' || (typeof value === 'string' && value !== "");
    });
    if (allValuesPresent) {
      const result = await editUser(selectedUser[0].id, user, localUser.accessToken);
      if(result) {
        reLoadWindows();
      } 
    }else {
      window.alert("有欄位沒有值");
    }
  }

  async function downloadImage(imageSrc) {
    const image = await fetch(imageSrc)
    if (!image.ok) {
      throw new Error(`图片下载失败: ${image.status} ${image.statusText}`);
    }

    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
  
    const link = document.createElement('a')
    link.href = imageURL
    const fileName = imageSrc.substring(imageSrc.lastIndexOf('/') + 1);
    link.download = fileName
    document.body.appendChild(link)
    await new Promise(resolve => setTimeout(resolve, 100));
    link.click()
    document.body.removeChild(link)
  }

  const renderIdList = (accountId) => {
    const idList = localUser && localUser.account_level === 1 ? 
      searchPhyIdList.length == 0 ? phyIdList.filter(phyId => phyId.belong_u_id === accountId).sort((a, b) => a.seq - b.seq) : searchPhyIdList.filter(phyId => phyId.belong_u_id === accountId).sort((a, b) => a.seq - b.seq) : 
      searchPhyIdList.length == 0 ? phyIdList : searchPhyIdList;
    return (
      idList!= null ? 
      idList.map((phyId, index) => {
        return(
          <tr className={`hover ${index % 2 != 0 ? "odd-row" : ""}`}>
            {localUser && localUser.account_level === 1 ? selectedPhyIds.includes(phyId.id) ? <td><FaRegCheckSquare className="select-icon" onClick={() => togglePhyIdSelection(phyId.id)}/> </td>: <td><FaRegSquare className="select-icon"  onClick={() => togglePhyIdSelection(phyId.id)}/></td> : ""}
            <td>{phyId.phy_id_string}</td>
            <td>{phyId.name}</td>
            <td>{handleTranslateCertType(phyId.cert_type)}</td>
            <td>{phyId.seq}</td>
            {localUser && localUser.account_level === 1 ?
              <td>
                <AiOutlineDelete  className="delete-icon" onClick={ () => handleOnShowConfirm(phyId) }/> 
                <AiFillEdit className="select-icon" onClick={() => {handelShowEditPhyId(phyId, idList)}}/>
                {phyId.id_pic_path != null ? 
                  <AiOutlineCheckCircle className="confirm-icon" onClick={() => downloadImage(phyId.id_pic_path) } /> : <AiOutlineCloseCircle />}
              </td>
              : 
              <td>
                <AiFillEdit className="select-icon" onClick={() => {handelShowEditPhyId(phyId)}}/>
                {localUser.user_login === "000" ? null : phyId.id_pic_path != null ? 
                <AiOutlineCheckCircle className="confirm-icon" onClick={() => handelShowEditPhyIdPic(phyId)}/> : <AiOutlineUpload className="upload-icon" onClick={() => handelPicUpload(phyId)} /> }
              </td>
            }
          </tr>
        )
      }) : ""
    )
  };

  const renderManagerPage = () => {
    const renderAccountList = searchAccountList.length != 0 ? searchAccountList : search != "" ? [] : accountList;
    return (
      <div className="account-data-form">
        <table>
          <thead>
            <th> {"  "} </th>
            {accountSortItemList.map((content, index) => {
              return(
                <th 
                  onClick={() => { handleUserSort(content.name) }}>
                    {content.title}
                    {accountSortSelect === content.name ? renderSortIcon(content.sortKey) : null}
                </th>
              )
            })}
            {localUser && localUser.account_level === 1 ? <th>功能</th> : ""}
          </thead>
          <tbody>
          {renderAccountList != null ? 
            renderAccountList.map((account, index) => {
              const isContained = phyIdList.some((id) => id.belong_u_id === account.id);
              const handleOnEdit = () => {
                handelShowEditUser(account.id);
              }
              return (
                <>
                <tr className={`hover ${index % 2 != 0 ? "odd-row" : ""}`}>
                  { isContained ? 
                    <td >{expandedRows.includes(account.id) ? <BiMinus className="select-icon" onClick={() => handleToggleSublist(account.id)}/> : <BiPlus className="select-icon" onClick={() => handleToggleSublist(account.id)}/>}</td>
                    : <td>{"  "}</td>}
                  <td>{account.user_login}</td>
                  <td>{account.user_email}</td>
                  <td>{account.user_pass}</td>
                  <td>{account.program_pass}</td>
                  <td>{account.nickname}</td>
                  <td>{account.telephone}</td>
                  <td>{account.program_fee}</td>
                  <td>{account.delivery_fee}</td>
                  <td>{account.user_group}</td>
                  <td><AiOutlinePlusCircle  className="plus-icon" onClick={ () => handleOnShow(account) }/> <AiFillEdit className="select-icon" onClick={handleOnEdit}/></td>
                </tr>
                {expandedRows.includes(account.id) && (
                  <tr>
                    <td colSpan="7">
                      <table>
                        <thead>
                          <th>
                            {selectAllPhyIds ? <FaRegCheckSquare className="select-icon" onClick={() => toggleAllPhyIdSelection(account.id)}/> : <FaRegSquare className="select-icon" onClick={() => toggleAllPhyIdSelection(account.id)}/>}
                          </th>
                          {idSortItemList.map((content, index) => {
                            return(
                              <th
                                onClick={() => { handlePhyIdSort(content.name) }}>
                                  {content.title}
                                  {phyIdSortSelect === content.name ? renderSortIcon(content.sortKey) : null}
                              </th>
                            )
                          })}
                          <th>功能</th>
                        </thead>
                        <tbody>
                          {renderIdList(account.id)}
                          {selectedPhyIds.length != 0 ?
                            <tr>
                              <td colSpan="6">
                                <button className="delete-row" onClick={handleOnShowConfirmAll}>刪除選擇項目</button>
                              </td>
                              
                            </tr> : null}
                        </tbody>
                      </table>
                      
                    </td>
                  </tr>
                )}
                </>
              )
            }) : ""}
          </tbody>
        </table>
      </div>
    )
  }

  const renderUserPage = () => {
    return (
      <div className="account-data-form">
        <table>
          <thead>
            {idSortItemList.map((content, index) => {
              return(
                <th 
                  onClick={() => { handlePhyIdSort(content.name) }}>
                    {content.title}
                    {phyIdSortSelect === content.name ? renderSortIcon(content.sortKey) : null}
                </th>
              )
            })}
            <th>身分證上傳</th>
          </thead>
          <tbody>
            {renderIdList(localUser.id)}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      <div className="idmanager-root">
        <Nav/>
        <div className="manager-background">
          <div className="manager-title">會員</div>
          <div className={`${localUser &&  localUser.account_level === 1 ? "id-manager-main-display" : "id-manager-main-display-customor"}`}>
            <div className="button-bar">
              <MDBInputGroup
                material
                containerClassName="search-block col-12"
                hint="Search.."
                onChange={handleSearchTextChange}
                onKeyPress={handleKeyPress}
                append={
                  <button
                    rounded
                    size="sm"
                    type="submit"
                    className="mr-auto search-button"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                }
              />
              {localUser && localUser.account_level === 1 ? 
                <button
                  className="register-btn"
                  onClick={handleOnShowSignUp}>
                    註冊
                </button>
              : <button
                  className="register-btn"
                  onClick={() => handleOnShow(localUser)}>
                    新增
                </button>}
            </div> 
            {localUser ?  localUser.account_level === 1 ? renderManagerPage(): renderUserPage() : ""}
          </div>
        </div>
      </div>
      <NewId 
        show={showNewId}
        onHide={handleOnHide}
        userId={newIdUserId}
        reLoadWindows= {reLoadWindows}
        data={null}
        editIdList={null}
        localUser={localUser}
        submitText="新增"
        title="新增會員"
        function={hadelNewPhyId}/>
      <NewId 
        show={showEditPhyId}
        onHide={handelOnHideEditPhyId}
        userId={newIdUserId}
        localUser={localUser}
        reLoadWindows= {reLoadWindows}
        data={editPhyId}
        editIdList={editPhyIdList}
        submitText="修改"
        title="編輯會員"
        function={hadelEditPhyId}/>
      <Confirm
        show={showConfirm}
        onHide={handleOnHideConfirm}
        phyIdInfo={selectedPhyId}
        message={"確認要刪除此身分證資訊嗎?"}
        reLoadWindows= {reLoadWindows}
        function= {handelRemoveId}/>
      <Confirm
        show={showConfirmAll}
        onHide={handleOnHideConfirmAll}
        message={"確認要刪除所選擇的股票資訊嗎?"}
        function= {handelRemoveAllPhyId}/>
      <SignUp 
        show={showSignUp}  
        onHide={handleOnHideSignUp}
        data={null}
        submit="新增"
        title="註冊使用者"
        function={signUp} />
      <SignUp
        show={showEditUser}
        onHide={handelOnHideEditUser}
        data={selectedUser}
        submit="修改"
        title="編輯使用者"
        function={handelEditUser}/>
      <UploadPhyPic
        show={showUploadPhyPic}
        onHide={handelOnHideUploadPhyPic}
        phyId={uploadPicPhyId}
        user={localUser}
        function={handelUploadFile}/>
      <UploadPhyPic
        show={showEditPhyIdPic}
        onHide={handelOnHideEditPhyIdPic}
        phyId={uploadPicPhyId}
        user={localUser}
        function={handelUploadFile}/>
    </>
  )
}

export default connect(
  null,
  { getUserByLevel, 
    getphyIdList, 
    addId,
    editId,
    removeId, 
    getIdListByBelogId, 
    addUser, 
    editUser, 
    uploadPhyPic, 
    getPhyIdPic }
)(IdManagementPage);